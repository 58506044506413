<template>
  <component :is="'new-appointment'" :appointment-id="id" />
</template>

<script>
  import NewAppointment from '../../views/Pages/Appointment/NewAppointment'

  export default {
    props: {
      appointmentId: String
    },
    components: {
      NewAppointment
    },
    computed: {
      id: function () {
        return this.appointmentId;
      }
    }
  }
</script>
